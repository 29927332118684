<template>
    <div class="container">
      <PrimePreloader v-if="loading"/>
      <div v-else class="my-4">
        <h4 class="text-center my-3">Участники</h4>
        <TabView>
            <TabPanel header="Участники">
                <DataTable :value="members" showGridlines stripedRows
                   :globalFilterFields="['name']"
                   v-model:filters="filters"
                   :paginator="true"
                   :rows="10"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,20,50]"
                   responsiveLayout="scroll"
                   currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
  
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск"/>
              </span>
            </div>
          </template>
          <Column field="barcode" header="Баркод"></Column>
          <Column header="ФИО">
            <template #body="{data}">
              {{ data.last_name }} {{ data.first_name }}
            </template>
          </Column>
          <Column header="Действие">
            <template #body="{data}">
              <Button class="p-button-danger" icon="pi pi-trash" label="Удалить" @click="deleteMembers(data.id)"/>
            </template>
          </Column>
  
        </DataTable>

            </TabPanel>
            <TabPanel header="Заявки">
                <DataTable :value="membersJoin" showGridlines stripedRows
                   :globalFilterFields="['barcode', 'lastname', 'firstname']"
                   v-model:filters="filters"
                   :paginator="true"
                   :rows="10"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,20,50]"
                   responsiveLayout="scroll"
                   currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
  
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск"/>
              </span>
            </div>
          </template>
          <Column field="barcode" header="Баркод"></Column>
          <Column header="ФИО">
            <template #body="{data}">
              {{ data.last_name }} {{ data.first_name }}
            </template>
          </Column>
          <Column header="Действие">
            <template #body="{data}">
              <Button class="p-button-success me-2" icon="pi pi-check" label="Принять" @click="updateMemberStatus(data.id, 1)"/>
              <Button class="p-button-danger" icon="pi pi-times" label="Отказать" @click="updateMemberStatus(data.id, 2)"/>
            </template>
          </Column>
  
        </DataTable>
            </TabPanel>
        </TabView>  
  
      </div>
    </div>
  </template>
  
  
  <script>
  import {FilterMatchMode, FilterOperator} from "primevue/api";
  import {mapActions, mapState} from 'vuex'
  export default {
    name: "OrganizationJoining",
    data() {
      return {
        loading: true,
  
        filters: {
          'global': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        },
      }
    },
    computed: {
      ...mapState('organization', ['organizations', 'statuses', 'members', 'membersJoin']),
    },
    methods: {
      ...mapActions('organization', ['GET_ORGANIZATION_MEMBERS', 'PUT_ORGANIZATION_MEMBERS_STATUS', 'DELETE_ORGANIZATIONS_MEMBERS']),

      async updateMemberStatus(id, status){


        const postRes = await this.PUT_ORGANIZATION_MEMBERS_STATUS({id, status})
        if (postRes) {
          await this.GET_ORGANIZATION_MEMBERS()
          this.$message({title: 'Статус обновлен'})
        }
     },
     async deleteMembers(delete_id){
       await this.DELETE_ORGANIZATIONS_MEMBERS(delete_id)
       await this.GET_ORGANIZATION_MEMBERS()
       this.$message({title: 'Успешно удалено'})
       // const deleteRes = await this.DELETE_ORGANIZATIONS_MEMBERS(delete_id)
       // if (deleteRes) {
       //   await this.GET_ORGANIZATION_MEMBERS()
       //   this.$message({title: 'Успешно удалено'})
       // }
     }
    },
    async mounted() {
      await this.GET_ORGANIZATION_MEMBERS();
  
      this.loading = false
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  